import { get } from 'lodash';

const PWT_ENABLED_PAGES = [
  'new_type_index',
  'new_model_core',
  'used_model_core_review',
  'used_model_core_feature_specs',
  'new_model_core_consumer_reviews',
  'used_model_core_consumer_reviews',
  'used_model_core_mpg',
  'used_car_inventory_srp',
  'new_car_inventory_srp',
  'new_used_car_inventory_srp',
  'used_model_car_inventory_vin_detail',
];

/*
 * PAY ATTENTION, THE FUNCTION IS USED in server/views/partials/gpt.ejs around line 42.
 */
export function getPwtEnabled(state) {
  const uspString = get(state, 'visitor.privacy.uspString', '');
  const isUserAllowed = uspString[1] !== 'N' && uspString[2] !== 'Y';
  const isEnablePubmaticOpenWrap = get(state, 'featureFlags.pubmaticOpenWrap', false);
  const pageName = get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name');

  return isUserAllowed && isEnablePubmaticOpenWrap && PWT_ENABLED_PAGES.includes(pageName);
}
